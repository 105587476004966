<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>문제행동 고객관리</h1>
            <div class="is-right">
            </div>
        </div>

        <!--조회-->
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="250px">
                    <col width="80px">
                    <col width="140px">
                    <col width="70px">
                    <col width="120px">
                    <col width="80px">
                    <col width="150px">
                    <col width="80px">
                    <col width="120px">
                    <col width="70px">
                    <col width="120px">
                    <col width="110px">
                    <col width="120px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>조회기간</label></th>
                    <td>
                        <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                    maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                    maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                            </v-menu>
                        </div>
                    </td>
                    <th><label>고객번호</label></th>
                    <td><input type="text" class="jh-form" v-model="CUST_NO" @keyup.enter="selectbutton"></td>
                    <th><label>고객명</label></th>
                    <td><input type="text" class="jh-form" v-model="CUST_NM" @keyup.enter="selectbutton"></td>
                    <th><label>고객등급</label></th>
                    <td><v-select class="jh-form" :items="DS38_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="CUS_LEVEL"></v-select></td>
                    <th><label>계좌번호</label></th>
                    <td><input type="text" class="jh-form" v-model="ACCOUNT" oninput="this.value = this.value.replace(/\-/g, '')" @keyup.enter="selectbutton"></td>
                    <th><label>상담사</label></th>
                    <td><input type="text" class="jh-form" v-model="CSLT_MAN" @keyup.enter="selectbutton"></td>
                    <th><label>문제행동유형</label></th>
                    <td><v-select class="jh-form" :items="CRS015_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="PROB_BEHAV_CD"></v-select></td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="selectbutton">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <!--//조회-->

        <!--리스트-->
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>문제행동 고객 목록</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-light" @click="excelDownload('divGridProbBehavList', '문제행동고객관리 '+TODAY)">엑셀다운로드</v-btn>
                </div>
            </div>

            <data-tables
            ref="dataTable"
            id="divGridProbBehavList"
            dataTableClass=""
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            @dblclick:row="onClickRow2"
            :paginationOptions="paginationOptions"
            ></data-tables>
        </div>
        <!--//리스트-->
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import { mapGetters } from "vuex";
import { eventBus } from "@/store/eventBus.js";

export default {
    name: "MENU_E010401", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
    },
    components: {
        dataTables
    },
    data() {
        return {
            menuStartDate: false,
            menuEndDate: false,
            TODAY: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            from: moment().subtract(6, "days").format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            dates: [
                moment().subtract(6, "days").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
            ],
            headers: [
                { text: 'NO', value: 'ROWNUM', align: 'center', width: '50px', },
                { text: 'Contact일시', value: 'CONTACT_DATE', align: 'center', width: '150px', },
                { text: '고객명', value: 'CUST_NM', align: 'center', width: '100px', },
                { text: '고객번호', value: 'CUST_NO', align: 'center', width: '100px', },
                { text: '계좌번호', value: 'ACCOUNT', align: 'center', width: '140px', },
                { text: '고객등급', value: 'CUS_LEVEL_NM', align: 'center', width: '140px', },
                { text: '상담유형(대)', value: 'CNSL_TY_LCLS', },
                { text: '상담유형(중)', value: 'CNSL_TY_MDCLS', },
                { text: '상담유형(소)', value: 'CNSL_TY_SCLS', },
                { text: '문제행동유형', value: 'PROB_BEHAV_NM', align: 'center',width: '120px', },
                { text: '상담사명(사번)', value: 'CSLT_MAN', align: 'center', width: '140px',},
            ],

            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '600px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,
                disableSort: true,
                itemClass: 'isActiveRow',
                load: undefined,
            },
            paginationOptions: {
                totalVisible: 10
            },

            CRS015_COMBO: [{CD: '',CD_NM: '전체'},],
            DS38_COMBO: [{CD: '',CD_NM: '전체'},], // 고객등급

            CUST_NO: "",
            CUST_NM: "",
            ACCOUNT: "",
            CSLT_MAN: "",
            PROB_BEHAV_CD: "",
            CUS_LEVEL: "",

            FIRST_INQRY_YN: "Y",

            selectedRow: {},
        }
    },
    created() { 
        this.dataTableOptions.headers = this.headers;
    },
    mounted(){
        this.getSelBoxList();
    },
    computed: {
        ...mapGetters({
            geCallStt: 'callStore/GE_CALL_STATE',
            geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
        }),
        dataTableInstance() {
            return this.$refs.dataTable;
        },      
        initHeaders(){
            return {
                SERVICE: 'setting.system.cmmn-CmpgnAplyWork-manage',
                METHOD: "",
                TYPE: 'BIZ_SERVICE',
            };
        },
    },
    methods: {
        fromOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        changeDate(type) {
            if(type == 'start') {
                this.from = this.dates[0];
            } else if (type == 'end') {
                this.to = this.dates[1];
            }
        },
        checkDate() {
            if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(3, 'months').format('YYYY-MM-DD'))) {
                this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                return false;
            } 

            return true;
        },
        toOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }

                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        onClickRow(item, row) { 
            row.select(true);
            row.isSelected = true;
            this.selectedRow = row;
        },
        onClickRow2(e, { item }) {
            if (this.geCallStt == 'A_SETACW' && this.geCnslSaveYn == 'N') {
                this.common_alert(`상담이력을 저장해주세요.`, 'info');
                return false;
            } else if (this.geCallStt == 'TALKING') {
                this.common_alert(`통화중입니다.`, 'info');
                return false;
            } else {
                this.setActiveTab(item);
            }
        },
        setActiveTab(item) {
            this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
                id: "E010101",
                title: "콜상담 메인",
                link: "/E010101"
            });
            let custInfo = {};
            custInfo.SRCH_ACCOUNT = item.ACCOUNT.replace(/\-/g, '');
            custInfo.SRCH_CL_CID  = item.CUST_NO;
            custInfo.IVR_IN_NO = '';
            custInfo.IVR_IN_PATH = '';
            custInfo.IVR_STD_TIME = '';
            custInfo.IVR_MENT = '';
            this.$store.commit("callStore/MU_CUST_INFO", custInfo);

            eventBus.sendOnRecvRsvPop(custInfo);
        },
        selectbutton() {

            if(!this.checkDate()) return false;

            this.dataTableOptions.load = this.selectProBehav;

            if (this.dataTableOptions.page > 1)this.dataTableOptions.page = 1;
            else this.dataTableInstance.loadData();
        },

        async selectProBehav(loadOptions){

            if (this.selectedRow.isSelected) {
                this.selectedRow.select(false);
            }
            
            if (this.FIRST_INQRY_YN == "Y"){
                return this.FIRST_INQRY_YN = "N"
            }

            if (!this.selectValidate()) {
                return;
            }

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };
            requestData.headers["URL"] = "/api/phone/main/prob-behav/list";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            requestData.headers["PAGES_CNT"] = loadOptions.page; 

            requestData.sendData["STR_RECP_DT"] = this.dates[0].replace(/\-/g, "");
            requestData.sendData["END_RECP_DT"] = this.dates[1].replace(/\-/g, "");
            requestData.sendData["CUST_NO"] = this.CUST_NO;
            requestData.sendData["CUST_NM"] = this.CUST_NM;
            requestData.sendData["CSLT_MAN"] = this.CSLT_MAN;
            requestData.sendData["CUS_LEVEL"] = this.CUS_LEVEL;
            requestData.sendData["PROB_BEHAV_CD"] = this.PROB_BEHAV_CD;
            requestData.sendData["ACCOUNT"] = this.ACCOUNT;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                let header = response.HEADER;
                let data = response.DATA;

                data.forEach(item => {
                    item.ACCOUNT = item.ACCOUNT.indexOf('-') < 0 ? 
                        this.mixin_accNoFormat(item.ACCOUNT) : this.mixin_accNoFormat(item.ACCOUNT.replace(/\-/g, ''));
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT
                };
            }
        },

        async getSelBoxList() {
            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
            requestData.headers["METHOD"] = "selectCode";
            requestData.headers["ASYNC"] = false;

            let sendList = [];
            sendList.push("CRS015"); //문제행동
            sendList.push("DS38"); //고객 등급

            requestData.sendData["GROUP_CD"] = sendList

            const response = await this.common_postCall(requestData);

            console.log(response)

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

                for (var i = 0; i < response.DATA.length ; i++){

                    if (response.DATA[i].GROUP_CD == "CRS015"){
                        this.CRS015_COMBO.push(response.DATA[i]);
                    }else if (response.DATA[i].GROUP_CD == "DS38"){
                        this.DS38_COMBO.push(response.DATA[i]);
                    }
                }
            }
        },
                 
        excelDownload(table_id, file_name) {
            //number,date,time,string - n,d,t,s
            var exceltypeArr = {};
            exceltypeArr[0] = "n";           
            exceltypeArr[1] = "s";  
            exceltypeArr[2] = "s";       
            exceltypeArr[3] = "s";     
            exceltypeArr[4] = "s";     
            exceltypeArr[5] = "s";     
            exceltypeArr[6] = "s"; 
            exceltypeArr[7] = "s"; 
            exceltypeArr[8] = "s"; 
            exceltypeArr[9] = "s"; 
            exceltypeArr[10] = "s";


            this.table_id = table_id
            this.file_name = file_name
            this.mixin_common_exportExcel(exceltypeArr)
        },

        selectValidate() {
            let val = true;
            if (this.mixin_isEmpty(this.dates[0])){
                this.common_alert(`조회시작일자가 없습니다`,'error')
                val = false;
            }else if (this.mixin_isEmpty(this.dates[1])){
                this.common_alert(`조회종료일자가 없습니다`,'error')
                val = false;
            }

            return val;
        },
        
    },
};
</script>

<style></style>  